<template>
  <div class="vertical" v-if="showConfirm">
    <ConfAnimation />
  </div>
<div class="vertical" v-else>
  <span v-text="element + ' wirklich löschen?'"></span>
  <div>
    <div class="form-line">
      <button @click="deleteElement()" class="button no">Löschen</button>
      <button @click="this.$router.back()" class="button normal">Abbrechen</button>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import ConfAnimation from "@/components/ConfAnimation";

export default {
  name: "DeleteElement",
  components: {ConfAnimation},
  props: {
    element: String,
    id: String,
  },
  data: function () {
    return {
      showConfirm: false,
    }
  },
  methods: {
    deleteElement() {
      axios.delete(this.apibase + '/' + this.element + '/' + this.id, {
        headers: {
          'Token': localStorage.getItem('token'),
        }
      }).then(response => {
        localStorage.setItem('token', response.config.headers.Token);
        if (response.status === 200) {
          this.showConfirm = true;
          window.setTimeout(() => {
            this.$router.back();
          }, 2000);
        } else {
          console.log("Server returned " + response.status + " : " + response.statusText);
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
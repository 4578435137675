<template>
    <div class="content">
      <router-view></router-view>
    </div>
    <MainMenu/>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import axios from "axios";

export default {
  name: 'App',
  components: {
    MainMenu
  },
  mounted() {
    axios.get(this.apibase + '/user/' + localStorage.getItem('user'), {
      headers: {
        'Token': localStorage.getItem('token'),
      }
    }).then(response => {
      localStorage.setItem('token', response.config.headers.Token);
      if (response.status === 200) {
        this.emitter.emit('logon', localStorage.getItem('isadmin'));
        //this.emitter.emit('logon', null);
        console.log('Logged in user: ' + localStorage.getItem('user'))
        this.$router.push('/workplan');
      }
    }).catch(() => {
      this.$router.push('/login');
    });
  }
}

</script>

<style>
body, html {
  margin: 0;
  height: 100%;
}

#app {
  display: grid;
  grid-template-rows: 90% 10%;
  height: 100%;
  margin: 0;
  font-family: Montserrat;
  position: relative;
}

@font-face {
  font-family: Montserrat;
  src: url("../public/font/montserrat/Montserrat-Regular.ttf");
}

.content {
  overflow: auto;
  margin-bottom: 1%;
  padding: 1em;
  position: relative;
}

.button, button {
  border-radius: 20px;
  border: none;
  color: white;
  text-transform: uppercase;
  font-size: 1em !important;
  font-family: Montserrat;
  padding: .2em .6em;
}

.button.yes, button.yes {
  background: green;
}

.button.no, button.no {
  background: red;
}

.button.normal, button.normal {
  background: grey;
}
</style>

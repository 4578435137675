<template>
<div class="loader"></div>
</template>

<script>
export default {
  name: "LoaderAnimation"
}
</script>

<style scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 4em;
  height: 4em;
  animation: spin 2s linear infinite;
  margin-top: 2em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
<template>
  <div class="vertical">
    <font-awesome-icon icon="fa-solid fa-left-long" @click="$router.push('/users')"/>
    <LoaderAnimation v-if="showLoader"/>
    <ConfAnimation v-if="showConfirm" />
    <div class="vertical" v-if="!showLoader && !showConfirm">
      <h3 v-text="'Account von ' + user.firstname + ' bearbeiten'"></h3>
      <span v-text="'username: ' + user.username"></span>
      <input type="email" v-model="user.email"/>
      <input type="text" v-model="user.firstname"/>
      <input type="text" v-model="user.lastname"/>
      <div v-if="admin">
        <font-awesome-icon icon="fa-solid fa-envelope" class="button normal" @click="resetPassword()"/>
        <font-awesome-icon icon="fa-solid fa-circle-minus" class="button delete"
                           @click="$router.push('/delete/user/' + user.id)"/>
      </div>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import axios from "axios";
import LoaderAnimation from "@/components/LoaderAnimation";
import ConfAnimation from "@/components/ConfAnimation";

export default {
  name: "UserView",
  components: {ConfAnimation, FontAwesomeIcon, LoaderAnimation},
  props: {
    userID: String
  },
  data: function () {
    return {
      user: {},
      showLoader: true,
      showConfirm: false,
      admin: '',
    }
  },
  mounted() {
    this.admin = localStorage.getItem('isadmin');
    this.getUser();
  },
  methods: {
    getUser() {
      console.log('userview')
      console.log(localStorage.getItem('token'))
      axios.get(this.apibase + '/user/' + this.userID, {
        headers: {
          'Token': localStorage.getItem('token'),
        }
      }).then(response => {
        localStorage.setItem('token', response.config.headers.Token);
        if (response.status === 200) {
          this.user = response.data[0];
          this.showLoader = false;
        } else {
          console.log("Server returned " + response.status + " : " + response.statusText);
        }
      });
    },
    resetPassword() {
      axios.get(this.apibase + '/user/changepw/' + this.userID, {
        headers: {
          'Token': localStorage.getItem('token'),
        }
      }).then(response => {
        localStorage.setItem('token', response.config.headers.Token);
        this.showConfirm = true;
        window.setTimeout(() => {
          this.showConfirm = false;
        }, 2000);
      })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>

<style scoped>

</style>
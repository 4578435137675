<template>
  <div class="vertical">
    <div v-if="!showConfirm">
      <h2>Neuen Urlaub eintragen</h2>
      <form id="shift-form" method="POST">
        <div class="form-line">
          Name
          <select name="user" id="user" v-model="user">
            <option v-for="user of users" :value="user.id" :key="user.id"
                    v-text="user.firstname + ' ' + user.lastname"></option>
            <!-- :selected="this.userID==user.id?'selected':''" -->
          </select>
        </div>
        <div class="form-line">
          Von
          <input type="date" v-model="start"/>
        </div>
        <div class="form-line">
          Bis
          <input type="date" v-model="end"/>
        </div>
        <div class="form-line">
          <input type="submit" class="button yes" v-on:click.prevent="submitForm()" value="Speichern"/>
          <button class="no" @click="this.$router.push('/vacation')">Abbrechen</button>
        </div>
      </form>
    </div>
    <ConfAnimation v-else/>
  </div>
</template>

<script>
import axios from "axios";
import ConfAnimation from "@/components/ConfAnimation";

export default {
  name: "AddVacation",
  components: {ConfAnimation},
  data: function () {
    return {
      users: [],
      selectedUser: {},
      user: '',
      start: '',
      end: '',
      showConfirm: false,
    }
  },
  methods: {
    submitForm() {
      let formData = new FormData;
      formData.append('user', this.user);
      formData.append('start', this.start);
      formData.append('end', this.end);

      axios.post(this.apibase + '/vacation', formData, {
        headers: {
          'Token': localStorage.getItem('token'),
        }
      })
          .then(response => {
            localStorage.setItem('token', response.config.headers.Token);
            this.showConfirm = true;
            window.setTimeout(() => {
              this.$router.push('/vacation');
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    resetForm() {
      this.user = '';
      this.start = '';
      this.end = '';
    },
    getUsers() {
      axios.get(this.apibase + '/user/' + localStorage.getItem('user'), {
        headers: {
          'Token': localStorage.getItem('token'),
        }
      }).then(response => {
        localStorage.setItem('token', response.config.headers.Token);
        if (response.status === 200) {
          for (let d of response.data) {
            this.users.push(d);
          }
          this.showLoader = false;
        } else {
          console.log("Server returned " + response.status + " : " + response.statusText);
        }
      });
    },
  },
  mounted() {
    this.user = localStorage.getItem('user');
    this.getUsers();
  }
}
</script>

<style scoped>

</style>